<template>
  <section role="main" class="content-body">
    <header class="page-header">
      <h2>{{ title }}</h2>
    </header>

    <div class="p-4">
      <div class="row">
        <div class="col-md-10 w3-border-right">
          <div class="p-4 w3-white">
            <h3 class="mb-5">Submit inquiry, complaint or feedback</h3>
            <form
              @submit.prevent="submitComplaint"
              id="submitComplaint"
              method="POST"
              enctype="multipart/form-data"
              style="max-width: 840px"
            >
              <div class="row">
                <div class="col-md-7">
                  <label class="my-0">Fund</label>
                  <div class="form-group">
                    <select
                      name="FundCode"
                      v-model="Complaint.FundCode"
                      class="form-control"
                    >
                      <option
                        v-for="Fund in Products"
                        :key="Fund.FundCode"
                        :value="Fund.FundCode"
                      >
                        {{ Fund.InvestmentName }}
                      </option>
                    </select>
                  </div>
                  <label class="my-0">Select a category</label>
                  <div class="form-group">
                    <select
                      name="Category"
                      v-model="Complaint.Category"
                      class="form-control"
                    >
                      <option
                        v-for="category in Complaints_GetCategory"
                        :key="category.Id"
                        :value="category.Category"
                      >
                        {{ category.Category }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="control-label"
                      >Inquiry/Complaint/Feedback</label
                    >
                    <textarea
                      name="Description"
                      v-model="Complaint.Description"
                      class="form-control"
                      rows="5"
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-5 pr-2">
                  <button
                    @click.prevent="addFile"
                    class="w3-button w3-small w3-border py-1 mb-3"
                  >
                    + Add attachment
                  </button>
                  <div
                    v-for="key in Attaches"
                    :key="key"
                    class="mb-2 mr-1 w3-border"
                  >
                    <div class="row g-0">
                      <div class="col-sm-10">
                        <input
                          class="w3-input border-0 py-1 attachments"
                          type="file"
                          :name="'attachments[]'"
                        />
                      </div>
                      <div class="col-sm-2">
                        <button
                          @click.prevent="removeFile(key)"
                          class="w3-button w3-white w3-hover-white w3-text-red w3-small"
                        >
                          x
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button
                    type="submit"
                    class="w3-button w3-red w3-hover-orange mt-4 px-4 py-3 rounded"
                  >
                    SEND INQUIRY
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end: page -->
  </section>
</template>
<script>
import { ref, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { v4 as uuid } from "uuid";
import axios from "axios";
import $ from "jquery";
export default {
  components: {},
  setup() {
    const store = useStore();
    const title = ref("Help & Feedback");
    const ContactNo = computed(() => store.state.userData.contactNo);
    const EMail = computed(() => store.state.userData.EMail);
    const Fullname = computed(() => store.state.userData.Account_Name);
    const Complaints_GetCategory = computed(
      () => store.state.Complaints_GetCategory
    );
    const Category_fetching = computed(
      () => store.state.fetching.Complaints_GetCategory
    );
    if (!Complaints_GetCategory.value.length) {
      store.dispatch("Complaints_GetCategory");
    }
    const Products = computed(() => store.state.Products);
    const Products_fetching = computed(() => store.state.fetching.Products);
    if (!Products.value.length) {
      store.dispatch("GetProducts");
    }
    const Category = ref({});
    const Complaint = ref({
      FundCode: "",
      Category: "",
      Description: "",
      ContactNo: ContactNo.value,
      Email: EMail.value,
      Fullname: Fullname.value,
      callback: "complaints",
    });
    const Attaches = ref([]);
    const addFile = () => {
      Attaches.value.push(uuid());
    };
    const removeFile = (uuid) => {
      Attaches.value = Attaches.value.filter((fuuid) => fuuid !== uuid);
    };

    const submitComplaint = () => {
      if (!Complaint.value.FundCode) {
        store.dispatch("showAlert", {
          message: "Please select Fund type",
          status: "error",
          showing: true,
        });
        return false;
      }
      if (!Complaint.value.FundCode) {
        store.dispatch("showAlert", {
          message: "Please select Fund type",
          status: "error",
          showing: true,
        });
        return false;
      }
      if (!Complaint.value.Category) {
        store.dispatch("showAlert", {
          message: "Please select category",
          status: "error",
          showing: true,
        });
        return false;
      }
      if (!Complaint.value.Description) {
        store.dispatch("showAlert", {
          message: "Please enter a Description",
          status: "error",
          showing: true,
        });
        return false;
      }

      var formBody = new FormData();
      for (var key in Complaint.value) {
        formBody.append(key, Complaint.value[key]);
      }

      $(".attachments").each(function () {
        formBody.append("attachment[]", $(this)[0].files[0]);
      });
      store.dispatch("showIndicator");
      axios({
        method: "post",
        url: "/api/Complaints/Send",
        data: formBody,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          store.dispatch("hideIndicator");
          if (response.data.status === "success") {
            Complaint.value = {};
            Attaches.value = [];
            store.dispatch("showAlert", {
              message: "Message sent successfully",
              status: "success",
              showing: true,
            });
          } else {
            store.dispatch("showAlert", {
              message: response.data.result,
              status: "error",
              showing: true,
            });
          }
        })
        .catch(function (error) {
          store.dispatch("hideIndicator");
          store.dispatch("showAlert", {
            message: error.message,
            status: "error",
            showing: true,
          });
        });
    };
    onBeforeMount(() => {
      store.dispatch("resetIdleTime");
    });
    return {
      addFile,
      removeFile,
      ContactNo,
      EMail,
      Fullname,
      Complaints_GetCategory,
      Products,
      Products_fetching,
      Category_fetching,
      uuid,
      title,
      Category,
      Complaint,
      Attaches,
      submitComplaint,
    };
  },
};
</script>
<style scoped>
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  opacity: 0;
  outline: none;
  background: #fff;
  cursor: pointer;
  display: block;
}
</style>